import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.div`
  textalign: center;
  cursor: pointer;
  font-family: ${(props) => props.theme.font};
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) =>
    props.text === 'Select' ? props.theme.primary : props.theme.secondary};
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin: 3rem auto 0;
`;

const StyledArrow = styled.span`
  margin-right: 1rem;
`;

const Button = ({ callback, text }) => {
  return (
    <StyledButton text={text} onClick={callback}>
      <StyledArrow aria-label='right arrow icon'>&gt;</StyledArrow>
      {text}
    </StyledButton>
  );
};

export default Button;
