import { useState } from 'react';
import FlipMove from 'react-flip-move';
import axios from 'axios';
import styled from 'styled-components';

import Character from './Character';
import Mom from './Mom';
import Button from './Button';
import Results from './Results';
import Footer from './Footer';
import listOfCharacters from '../data/listOfCharacters';
import '../index.css';

import { shuffleArray } from '../util/utility';
import StreamOverlay from './StreamOverlay';

const StyledWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: calc(100vh - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledText = styled.div`
  text-align: center;
  padding-top: 3rem;
  font-family: ${(props) => props.theme.font};
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.primary};
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin: 0 auto;
  ${(props) => props.visibility && 'visibility: hidden'};
`;

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [characters, setCharacters] = useState(listOfCharacters);
  const [number, setNumber] = useState(null);
  const [positions, setPositions] = useState({});
  const [count, setCount] = useState(0);
  const [random, setRandom] = useState([]);
  const active = count > 0 ? true : false;

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // For live production
  async function generateRandomInteger(apiKey, n, length, min, max) {
    try {
      setIsLoading(true);
      let { data } = await axios.post(
        'https://api.random.org/json-rpc/4/invoke',
        {
          jsonrpc: '2.0',
          method: 'generateIntegerSequences',
          params: {
            apiKey,
            n,
            length,
            min,
            max,
            replacement: false,
          },
          id: 45673,
        },
      );

      let randomShuffle = data.result.random.data;
      setIsLoading(false);

      setRandom(randomShuffle);
      startRandomPick(randomShuffle[0]);
    } catch (err) {
      console.log('Error occurred: ', err);
      throw new Error('Something went wrong!');
    }
  }

  // For testing
  async function localShuffle() {
    try {
      setIsLoading(true);

      const shuffle = [];
      for (let i = 0; i < 5; i++) {
        const tempShuffle = shuffleArray();
        shuffle.push(tempShuffle);
      }
      shuffle.push(Math.floor(Math.random() * (33 + 1)));

      let randomShuffle = shuffle;

      setIsLoading(false);

      setRandom(randomShuffle);
      startRandomPick(randomShuffle[0]);
    } catch (err) {
      console.log('Error occurred: ', err);
      throw new Error('Something went wrong!');
    }
  }

  const startRandomPick = (arr) => {
    let copy = [...characters];

    const res = copy.map((char, index) => {
      return characters[arr[index]];
    });

    setCharacters(res);
    setCount((prev) => prev + 1);
  };

  const [showName, setShowName] = useState(false);
  const [displayChar, setDisplayChar] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const reset = () => {
    setCharacters(listOfCharacters);
    setNumber(null);
    setCount(0);
    setRandom([]);
    setShowName(false);
    setDisplayChar(true);
    setShowResult(false);
    setShowOverlay(false);

    document.body.style.backgroundColor = '#4d4545';
  };

  if (showResult) {
    return (
      <>
        {showOverlay ? (
          <StreamOverlay
            setShowOverlay={setShowOverlay}
            showName={showName}
            character={characters[number]}
          />
        ) : (
          <>
            <Results
              showName={showName}
              reset={reset}
              character={characters[number]}
              setShowOverlay={setShowOverlay}
            />
            <Footer />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <StyledWrapper>
        <FlipMove
          className='characters-container'
          onFinishAll={async () => {
            await sleep(750);
            if (!active) return;
            if (count < 5) {
              startRandomPick(random[count]);
            } else if (count === 5) {
              setNumber(random[5]);
            }
          }}>
          {characters.map((character, index) => {
            return (
              <Character
                key={character.name}
                displayChar={!displayChar && characters[number]}
                character={character}
                positions={positions}
                setPositions={setPositions}
                index={index}
              />
            );
          })}
        </FlipMove>

        {number !== null && (
          <Mom
            bounds={positions[number]}
            setShowName={setShowName}
            setDisplayChar={setDisplayChar}
            setShowResult={setShowResult}
            sleep={sleep}
          />
        )}

        {isLoading ? (
          <StyledText>Shuffling...</StyledText>
        ) : random.length === 0 ? (
          <>
            {/* <Button callback={localShuffle} text='Select' /> */}
            <Button
              callback={() =>
                generateRandomInteger(
                  process.env.REACT_APP_API_KEY,
                  6,
                  [34, 34, 34, 34, 34, 1],
                  0,
                  33,
                )
              }
              text='Select'
            />
          </>
        ) : (
          <StyledText visibility='hidden'>Shuffling...</StyledText>
        )}
      </StyledWrapper>
      <Footer />
    </>
  );
}

export default App;
