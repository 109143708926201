const listOfCharacters = [
  {
    name: 'Isaac',
    image: '/assets/isaac.webp',
    selected: true,
    tainted: false,
  },
  {
    name: 'Magdalene',
    image: '/assets/magdalene.webp',
    selected: true,
    tainted: false,
  },
  {
    name: 'Cain',
    image: '/assets/cain.webp',
    selected: true,
    tainted: false,
  },
  {
    name: 'Judas',
    image: '/assets/judas.webp',
    selected: true,
    tainted: false,
  },
  {
    name: '???',
    image: '/assets/blue-baby.webp',
    selected: true,
    tainted: false,
  },
  {
    name: 'Eve',
    image: '/assets/eve.webp',
    selected: true,
    tainted: false,
  },
  {
    name: 'Samson',
    image: '/assets/samson.webp',
    selected: true,
    tainted: false,
  },
  {
    name: 'Azazel',
    image: '/assets/azazel.webp',
    selected: true,
    tainted: false,
  },
  {
    name: 'Lazarus',
    image: '/assets/lazarus.webp',
    selected: true,
    tainted: false,
  },
  {
    name: 'Eden',
    image: '/assets/eden.webp',
    selected: true,
    tainted: false,
  },
  {
    name: 'The Lost',
    image: '/assets/lost.webp',
    selected: true,
    tainted: false,
  },
  {
    name: 'Lilith',
    image: '/assets/lilith.webp',
    selected: true,
    tainted: false,
  },
  {
    name: 'Keeper',
    image: '/assets/keeper.webp',
    selected: true,
    tainted: false,
  },
  {
    name: 'Apollyon',
    image: '/assets/apollyon.webp',
    selected: true,
    tainted: false,
  },
  {
    name: 'The Forgotten',
    image: '/assets/forgotten.webp',
    selected: true,
    tainted: false,
  },
  {
    name: 'Bethany',
    image: '/assets/bethany.webp',
    selected: true,
    tainted: false,
  },
  {
    name: 'Jacob and Esau',
    image: '/assets/jacob-esau.webp',
    selected: true,
    tainted: false,
  },
  {
    name: 'Tainted Isaac',
    image: '/assets/tainted-isaac.webp',
    selected: true,
    tainted: true,
  },
  {
    name: 'Tainted Magdalene',
    image: '/assets/tainted-magdalene.webp',
    selected: true,
    tainted: true,
  },
  {
    name: 'Tainted Cain',
    image: '/assets/tainted-cain.webp',
    selected: true,
    tainted: true,
  },
  {
    name: 'Tainted Judas',
    image: '/assets/tainted-judas.webp',
    selected: true,
    tainted: true,
  },
  {
    name: 'Tainted ???',
    image: '/assets/tainted-blue-baby.webp',
    selected: true,
    tainted: true,
  },
  {
    name: 'Tainted Eve',
    image: '/assets/tainted-eve.webp',
    selected: true,
    tainted: true,
  },
  {
    name: 'Tainted Samson',
    image: '/assets/tainted-samson.webp',
    selected: true,
    tainted: true,
  },
  {
    name: 'Tainted Azazel',
    image: '/assets/tainted-azazel.webp',
    selected: true,
    tainted: true,
  },
  {
    name: 'Tainted Lazarus',
    image: '/assets/tainted-lazarus.webp',
    selected: true,
    tainted: true,
  },
  {
    name: 'Tainted Eden',
    image: '/assets/tainted-eden.webp',
    selected: true,
    tainted: true,
  },
  {
    name: 'Tainted Lost',
    image: '/assets/tainted-lost.webp',
    selected: true,
    tainted: true,
  },
  {
    name: 'Tainted Lilith',
    image: '/assets/tainted-lilith.webp',
    selected: true,
    tainted: true,
  },
  {
    name: 'Tainted Keeper',
    image: '/assets/tainted-keeper.webp',
    selected: true,
    tainted: true,
  },
  {
    name: 'Tainted Apollyon',
    image: '/assets/tainted-apollyon.webp',
    selected: true,
    tainted: true,
  },
  {
    name: 'Tainted Forgotten',
    image: '/assets/tainted-forgotten.webp',
    selected: true,
    tainted: true,
  },
  {
    name: 'Tainted Bethany',
    image: '/assets/tainted-bethany.webp',
    selected: true,
    tainted: true,
  },
  {
    name: 'Tainted Jacob',
    image: '/assets/tainted-jacob.webp',
    selected: true,
    tainted: true,
  },
];

export default listOfCharacters;
