import { forwardRef } from 'react';
import styled, { keyframes } from 'styled-components';
import '../index.css';

const CharacterWrapper = styled.div`
  width: 160px;
  height: 80px;
  font-family: ${(props) => props.theme.font};
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const fadeAnimation = () => keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const CharacterImage = styled.img`
  height: 48px;
  opacity: ${(props) => props.displayChar.name === props.character.name && 0};
  animation: ${(props) =>
      props.displayChar &&
      props.displayChar.name !== props.character.name &&
      fadeAnimation()}
    500ms ease-in;
  animation-fill-mode: forwards;
`;

const Character = forwardRef(
  ({ character, positions, setPositions, index, displayChar }, ref) => {
    return (
      <CharacterWrapper
        ref={ref}
        onLoad={(e) => {
          if (positions.length === 34) return;
          setPositions({
            ...positions,
            [index]: e.currentTarget.getBoundingClientRect(),
          });
        }}>
        <CharacterImage
          displayChar={displayChar}
          character={character}
          src={window.location.origin + character.image}
          alt={character.name}
          className={`character-image`}
        />
      </CharacterWrapper>
    );
  },
);

export default Character;
