import React, { useState, useEffect } from 'react';

const StreamOverlay = ({ setShowOverlay, character: { name } }) => {
  const [characterName, setCharacterName] = useState(name);
  const [showBackButton, setShowBackButton] = useState(false);
  useEffect(() => {
    document.body.style.backgroundColor = 'rgba(0, 0, 0, 0)';

    return () => (document.body.style.backgroundColor = '#4d4545');
  }, []);

  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
      }}
      onMouseEnter={() => setShowBackButton(true)}
      onMouseLeave={() => setShowBackButton(false)}>
      <input
        style={{
          textAlign: 'center',
          fontFamily: `'Press Start 2P', monospace`,
          fontWeight: 'bold',
          textTransform: 'uppercase',
          color: '#B248A1',
          fontSize: '1.5rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 auto',
          marginTop: '.5rem',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          border: 'none',
        }}
        value={characterName}
        onMouseLeave={() => document.activeElement.blur()}
        onChange={(e) => setCharacterName(e.target.value)}
      />
      {showBackButton && (
        <p
          style={{
            fontFamily: `'Press Start 2P', monospace`,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            color: '#B248A1',
            fontSize: '1rem',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            border: 'none',
            cursor: 'pointer',
            position: 'absolute',
            right: '1rem',
            top: '-0.5rem',
          }}
          onClick={() => {
            setShowOverlay(false);
            document.body.style.backgroundColor = '#4d4545';
          }}>
          Go Back
        </p>
      )}
    </div>
  );
};

export default StreamOverlay;
