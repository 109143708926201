import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  width: calc(100% - 1rem);
  text-align: right;
  margin-right: 1rem;
  color: ${(props) => props.theme.secondary};
  font-size: 0.6rem;
  max-height: 20px;
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.secondary};
`;

const Footer = () => {
  return (
    <StyledFooter>
      Powered by{' '}
      <StyledLink
        href='https://www.random.org'
        target='_blank'
        rel='noreferrer'>
        RANDOM.ORG
      </StyledLink>
    </StyledFooter>
  );
};

export default Footer;
