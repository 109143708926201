import { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import '../index.css';

const handDrop = (top) => keyframes`
  0% {
    transform: translateY(-100px);
  }

  90% {
    transform: translateY(${top + 100}px);
  }

  100% {
    transform: translateY(${top + 100}px);
  }
`;

const handRaise = (top) => keyframes`
  0% {
    transform: translateY(${top + 100}px);
  }
  
  30% {
    transform: translateY(${top + 100}px);
  }

  100% {
    transform: translateY(-${top + 100}px);
  }
`;

const MomWrapper = styled.div`
  width: 160px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  left: ${(props) => props.bounds.left}px;
  top: -100px;
  animation-iteration-count: 1;
  animation: ${(props) =>
      props.isClosed ? handRaise(props.bounds.top) : handDrop(props.bounds.top)}
    750ms ease-in;
`;

const MomImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Mom = ({ bounds, setShowName, setDisplayChar, setShowResult }) => {
  const [isClosed, setIsClosed] = useState(false);
  const image = isClosed
    ? '/assets/moms-hand-closed.png'
    : '/assets/moms-hand.png';

  return (
    <MomWrapper
      bounds={bounds}
      isClosed={isClosed}
      onAnimationEnd={(e) => {
        if (!isClosed) {
          setIsClosed(true);
          setShowName(true);
          setDisplayChar(false);
        } else {
          setShowResult(true);
        }
      }}>
      <MomImage src={window.location.origin + image} alt="Mom's hand" />
    </MomWrapper>
  );
};

export default Mom;
